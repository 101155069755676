import { atomizify, flagify } from 'atomizify'

atomizify({
  media_queries: {
    __xs: {
      query: 'max-width: 700px',
      description: 'extra small screens',
    },
    __m: {
      query: 'min-width: 700px',
      description: 'medium screens',
    },
  },
  custom_classes: {
    w_third: 'width: calc(100% / 3)',
    mondwest: 'font-family: "PP Mondwest"',
    arial: 'font-family: "Arial Rounded"',
    montreal: 'font-family: "Neue Montreal"',
    pink: 'color: #F501BF',
    bg_pink: 'background-color: #F501BF',
    green: 'color: #0DFE02',
    bg_green: 'background-color: #0DFE02',
    grey: 'color: #302B2B',
    bg_grey: 'background-color: #302B2B',
    b_rad40: 'border-radius: 40px',
    fs32: 'font-size: 32px',
    fs35: 'font-size: 35px',
    fs5vw: 'font-size: 5.25vw',
    fs6vw: 'font-size: 6.1vw',
    fs9vw: 'font-size: 12vw',
    fs10vw: 'font-size: 13vw',
    shadow: 'box-shadow: 0 0 150px black',
    h10vh: 'height: 10vh',
  },
})

export const { Component, Div, Span } = flagify()
