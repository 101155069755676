import { Fragment, useState, useEffect } from 'react'
import { Component, Div, Span } from './flags'

const preload_image = (folder, index, extension) => {
  const image = new Image()
  image.src = get_image_url(index, folder, extension)
}

const images = {
  home: { amount: 40, extension: 'jpeg' },
  moodboard: { amount: 16, extension: 'jpeg' },
  stories: { amount: 12, extension: 'jpg' },
}

const Home = () => {
  useEffect(() => {
    Object.entries(images).forEach(([folder, { amount, extension }]) => {
      const array = [...Array(amount).keys()]
      array.forEach((index) =>
        preload_image(
          folder,
          index + 1,
          (index + 1 > 24 && 'gif') || extension,
        ),
      )
    })
  }, [])

  return (
    <Fragment>
      <Page ai_center>
        <Header mt15 fs10vw__xs fs6vw>
          Making shit together{' '}
          <Span fs9vw__xs fs5vw arial>
            is cooler
          </Span>
        </Header>
        <Div none__m mv10 flex ai_center>
          <Div mr5 arial bg_grey white b_rad20 pv5 ph10>
            Tap on the pics!
          </Div>
          <Div ml5 arial bg_grey white b_rad20 pv5 ph10>
            Tap on the pics!
          </Div>
        </Div>
        <Main>
          <Content />
          <Images />
        </Main>
      </Page>
    </Fragment>
  )
}

const Images = () => {
  const max_index = images.home.amount
  return (
    <ImagesCarousels
      pt5__xs
      pa10
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridTemplateRows: '1.1fr 0.9fr ',
        columnGap: '10px',
        rowGap: '10px',
      }}
    >
      <ImagesCarousel
        section="home"
        style={{ gridColumn: '1 / 3', gridRow: '1' }}
        max_index={max_index}
        base_index={2}
      />
      <ImagesCarousel
        section="home"
        style={{ gridColumn: '3 / 9', gridRow: '1' }}
        max_index={max_index}
        base_index={31}
      />
      <ImagesCarousel
        section="home"
        style={{ gridColumn: '9 / 13', gridRow: '1' }}
        max_index={max_index}
        base_index={16}
      />
      <ImagesCarousel
        section="home"
        style={{ gridColumn: '1 / 7', gridRow: '2' }}
        max_index={max_index}
        base_index={26}
      />
      <ImagesCarousel
        section="home"
        style={{ gridColumn: '7 / 10', gridRow: '2' }}
        max_index={max_index}
        base_index={8}
      />
      <ImagesCarousel
        section="home"
        style={{ gridColumn: '10 / 13', gridRow: '2' }}
        max_index={max_index}
        base_index={40}
      />
    </ImagesCarousels>
  )
}

const ImagesCarousel = ({
  max_index,
  base_index,
  timeout,
  section,
  style,
  ...props
}) => {
  const [index, set_index] = useState(base_index)
  const image_url = get_image_url(index, section)

  const update_index = () => {
    const next_index = index === max_index ? 1 : index + 1
    set_index(next_index)
  }

  return (
    <Visual
      onMouseOver={update_index}
      onClick={update_index}
      style={{ background: `center / cover url(${image_url})`, ...style }}
      {...props}
    />
  )
}

const Content = () => {
  const [selected_content, set_selected_content] = useState(null)
  const [modal, set_modal] = useState(null)

  useEffect(() => {
    if (!modal) return
    modal.scrollTo(0, 0)
  }, [modal, selected_content?.id])

  return (
    <ContentWrapper
      tabIndex="0"
      w100p={selected_content}
      onKeyDown={({ key }) => key === 'Escape' && set_selected_content(null)}
    >
      {selected_content && (
        <Modal elemRef={set_modal}>
          {typeof selected_content.body === 'string' ? (
            <Text>{selected_content.body}</Text>
          ) : (
            selected_content.body
          )}
        </Modal>
      )}
      <Menu>
        {buttons.map(([button_text, content], index) => {
          const is_first = !index
          const is_last = index === buttons.length - 1
          const is_selected = content.title === selected_content?.title

          return (
            <Button
              key={index}
              fs40={is_last}
              mb20={is_last}
              mt20={is_first}
              o70={selected_content && !is_selected}
              className={`button ${is_selected ? 'selected_button' : ''}`}
              onClick={() => set_selected_content(is_selected ? null : content)}
            >
              {button_text}
            </Button>
          )
        })}
      </Menu>
    </ContentWrapper>
  )
}

const Values = () => (
  <Div flex flex_column w100p h100p>
    <Div uppercase flex flex_wrap>
      <Value fs26 fs21__xs arial>
        Playful
      </Value>
      <Value fw600 mondwest fs32 fs24__xs>
        Childhood meets innovation
      </Value>
      <Value fs26 fs21__xs arial>
        Participatory
      </Value>
      <Value fw600 mondwest fs32 fs24__xs>
        Bountiful
      </Value>
      <Value fs26 fs21__xs arial>
        Inviting
      </Value>
      <Value fw600 mondwest fs32 fs24__xs>
        Imperfectly thoughtful
      </Value>
    </Div>
    <Div
      mt20
      w100p
      h100p__m
      grid
      flex__xs
      flex_column__xs
      pb20__xs
      style={{
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridTemplateRows: '1.15fr 0.75fr',
        columnGap: '10px',
        rowGap: '10px',
      }}
    >
      <ImagesCarousel
        w100p__xs
        h200__xs
        section="moodboard"
        style={{ gridColumn: 'span 4', gridRow: '1' }}
        max_index={16}
        base_index={1}
      />
      <ImagesCarousel
        w100p__xs
        h200__xs
        section="moodboard"
        style={{ gridColumn: 'span 2', gridRow: '1' }}
        max_index={16}
        base_index={3}
      />
      <ImagesCarousel
        w100p__xs
        h200__xs
        section="moodboard"
        style={{ gridColumn: 'span 5', gridRow: '1' }}
        max_index={16}
        base_index={5}
      />
      <ImagesCarousel
        w100p__xs
        h200__xs
        section="moodboard"
        style={{ gridColumn: 'span 1', gridRow: '1' }}
        max_index={16}
        base_index={7}
      />
      <ImagesCarousel
        w100p__xs
        h200__xs
        section="moodboard"
        style={{ gridColumn: 'span 2', gridRow: '2' }}
        max_index={16}
        base_index={9}
      />
      <ImagesCarousel
        w100p__xs
        h200__xs
        section="moodboard"
        style={{ gridColumn: 'span 3', gridRow: '2' }}
        max_index={16}
        base_index={11}
      />
      <ImagesCarousel
        w100p__xs
        h200__xs
        section="moodboard"
        style={{ gridColumn: 'span 4', gridRow: '2' }}
        max_index={16}
        base_index={13}
      />
      <ImagesCarousel
        w100p__xs
        h200__xs
        section="moodboard"
        style={{ gridColumn: 'span 3', gridRow: '2' }}
        max_index={16}
        base_index={15}
      />
    </Div>
  </Div>
)

const What = () => (
  <Text>
    As we strongly believe that{' '}
    <i>
      <u>« making shit together is cooler »</u>
    </i>
    , our project serves as a creative co-creation platform on Instagram that
    invites people to contribute work on a monthly changing topic. <br />
    Our main goal is to actively bring designers together, make them feel
    encouraged, and stimulate creativity. <br />
    <br />
    To do that, we ask our community to submit images to a photo stock that is
    based on a playful, not-so-serious theme that will be announced every month.
    Those collectively generated image pools will become publicly accessible and
    are the base for further design development. This way we challenge our
    community as well as us (the 4 girls running this platform) to explore new
    design techniques and transform those images into interesting artworks. The
    visuals on the landing page are examples of possible outcomes that we've
    collected. <br />
    <br />
    Sharing, pushing, and celebrating the final outcome on Instagram will not
    only motivate participating but also be inspiring to see multiple
    interpretations of the same topic. As we praise collaboration we would like
    to also set up « open calls » where we see ourselves as wingwomen who
    connect designers that are interested in collaborating. <br />
    Because sometimes you just need one friend that introduces you to others &
    brings up fun projects to realize that:{' '}
    <i>
      <u>« making shit together is cooler »</u>
    </i>
    .
  </Text>
)

const Why = () => (
  <Text>
    The main idea of this project is to create a sense of community in times of
    separation: we believe there’s a need to open up a space that feels like a
    sunny living room.
    <br />A space where it’s easy to talk to each other and spontaneously
    exchange design ideas in a fun and playful way.
    <br />
    <br />
    Especially the feeling of being welcomed, encouraged and a valuable part of
    a bigger movement is something that we would love to bring to this world.
    Let’s make this design industry less about competition and intimidating each
    other but more about working together and supporting each other so that
    creativity can flourish!
    <br />
    <br />
    Furthermore, we have witnessed that for some people it can be hard to set up
    an actual collaboration project. With{' '}
    <i>
      <u>« making shit together is cooler »</u>{' '}
    </i>
    we would like to be the connecting person that introduces one to another and
    overcomes that first barrier of contacting someone - aren't we all so
    thankful to that one person who introduced us to others at a party and
    didn’t leave us standing alone in a corner? We want to be that person.
    <br />
    <br />
    This project is also for the young and hungry designers, who are full of
    passion and want to further explore their creativity. Speaking of
    experience: actually pursuing side projects whilst having a full-time job
    requires a lot of self-discipline, which can be challenging from time to
    time. Having a community and a deadline will make us and the other
    participants feel more energized, focused and at ease - because we’re doing
    this together.
  </Text>
)

const How = () => (
  <Text>
    As stated above, our project is all about having a community regularly
    contributing to build image pools that serve as a basis for further
    collaborative design development.
    <br />
    <br />
    We will mainly run this project on Instagram, so we'll make sure to produce
    content that works well across all formats of the app and uses features such
    as the question sticker.
    <br />
    At the beginning of each month, we define a topic and ask our community to
    send photos related to it. It’s important for us to choose themes implying
    that the participants have the requested image already on their phones, so
    that no extra work or time is needed ; we want this process to be as easy
    and low in effort as possible.
    <br />
    <br />
    Fun and playful topics that suit our platform could be: <br />
    <Span mr20 ml65 ml0__xs mr10__xs>
      •
    </Span>
    a screenshot of your spotify account <br />
    <Span mr20 ml65 ml0__xs mr10__xs>
      •
    </Span>
    the last picture you’ve taken <br />
    <Span mr20 ml65 ml0__xs mr10__xs>
      •
    </Span>
    something green on your camera roll <br />
    <Span mr20 ml65 ml0__xs mr10__xs>
      •
    </Span>
    your surrounding when you see this post <br />
    <Span mr20 ml65 ml0__xs mr10__xs>
      •
    </Span>
    your home screen <br />
    <Span mr20 ml65 ml0__xs mr10__xs>
      •
    </Span>
    a pic of what you did on the 18th of Jan
    <br />
    <br />
    Ideally, we’d receive around 50-100 images. After one week, the submission
    period will be closed. All pictures are going to be saved in one big file
    and will be shared with our community, so that everyone can access it at any
    time.
    <br />
    Now it’s about three weeks of creation time! The deadline will be a few days
    before the end of the month. Possible outcomes could be motion and 3D
    visuals, creative coding experiences and interactive tools, posters,
    fanzines, AR filters & stickers for Instagram, physical fun products such as
    fake tattoos, chewing gum, stickers, etc.
    <br />
    <br />
    It’s important to note that us (the 4 girls running this platform) will also
    take part in those design challenges and contribute design art works. <br />
    For those who are looking for collaborators for their project idea we want
    to set up some « open calls »: people can message us with their idea in mind
    and we connect them with people that would fit - either from our own network
    or by asking publicly on our account.
    <br />
    To motivate and inspire others while creating, we'll post our own work &
    progress, share our stories and talk about interesting design debates.
    <br />
    To actually submit their artworks, we ask the participants to send them via
    email, or to share them using our hashtag #makingshittogetheriscooler. This
    way we always keep building the community but also get more attention on our
    project.
    <br />
    <br />
    The last days of the month will be used to browse through the submissions,
    select the artworks that we really like and curate posts to share them. This
    way new talents can be found, emerging designers get a bigger platform and
    people will get inspired for their own practice.
    <br />
    For the ones who just sent in a photo at the very beginning, it will be very
    interesting to see how their picture has turned into something beautiful. It
    has very high importance to us to always credit the participants and push
    the projects that have been collectively created.
    <br />
    Finally, to intensify the sense of community we encourage designers to use
    hashtags, our VR filters, gifs, DM others to collaborate, or just chat about
    something funny - because:{' '}
    <i>
      <u>« making shit together is cooler »</u>
    </i>
    !
  </Text>
)

const Brand = () => (
  <Fragment>
    <Text>
      An initial exploration of how our branding could apply to Instagram:
    </Text>
    <Div
      mt35
      grid
      flex__xs
      flex_column__xs
      style={{
        gridTemplateColumns: 'repeat(4, 1fr)',
        columnGap: '30px',
        rowGap: '30px',
      }}
    >
      {[...Array(12).keys()].map((index) => (
        <Img
          ba
          w100p
          key={index}
          src={get_image_url(index + 1, 'stories', 'jpg')}
        />
      ))}
    </Div>
  </Fragment>
)

const About = () => (
  <Div>
    <Text>The 4 girls behind this project:</Text>
    <Div
      mt35
      grid
      flex__xs
      flex_column__xs
      style={{
        gridTemplateColumns: 'repeat(4, 1fr)',
        columnGap: '30px',
        rowGap: '30px',
      }}
    >
      {[...Array(4).keys()].map((index) => (
        <Img key={index} w100p src={get_image_url(index + 1, 'about', 'jpg')} />
      ))}
    </Div>
  </Div>
)

const buttons = Object.entries({
  what: {
    id: 1,
    title: 'What kind of shit are we making?',
    body: <What />,
  },
  why: {
    id: 2,
    title: 'What lead us to this project?',
    body: <Why />,
  },
  how: {
    id: 3,
    title: 'How this work would play out?',
    body: <How />,
  },
  values: {
    id: 4,
    title: 'Values',
    body: <Values />,
  },
  brand: { id: 5, title: 'Branding directions', body: <Brand /> },
  '🤝': {
    id: 6,
    title: 'Why choose us?',
    body: <About />,
  },
})

const get_image_url = (index, section, extension) => {
  const file_extension = extension || (index > 24 && 'gif') || 'jpeg'
  const image_file_name = `${section}_${index}.${file_extension}`
  const image_url = `./images/${section}/${image_file_name}`
  return image_url
}

const Page = Component.montreal.w100vw.h100vh.flex.flex_column.main()
const Header = Component.mondwest.w100p.text_center.uppercase.div()
const Main = Component.w100p.h100p.flex.relative.section()

const ImagesCarousels = Component.w100p.flex.flex_wrap.section()
const Visual = Component.b_rad20.div()

const ContentWrapper = Component.h100p.r0.absolute.flex.div()
const Menu =
  Component.zi1.h100p.flex.flex_column.ai_center.jc_between.pr30.pr10__xs.div()
const Button =
  Component.c_pointer.white.arial.shadow_a_s.ba0.c_pointer.uppercase.flex.jc_center.ai_center.fs15.fs10__xs.w75.h75.w45__xs.h45__xs.b_rad50p.button()

const Modal =
  Component.flex1.ph45.ph20__xs.pv15__xs.pv35.ma20.b_rad20.shadow.bg_white.ofy_scroll.relative.div()
const Text = Component.ws_pre_w.fs35.fs25__xs.ma0.w60p.w100p__xs.p()

const Value =
  Component.flex.ai_center.jc_center.ba.b_grey.b_rad40.bw2.ph30.ph15__xs.pv10.mr5.mb10.div()
const Img = Component.img()

export default Home
